.addPhoto {
  height: 500px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  p {
    text-align: center;
    margin-bottom: 10px;
  }
  .MuiButton-contained {
    background-color: $color-product-panel;
    padding: 15px 40px;
    border-radius: 50px;
    color: white;

    &:hover {
      background-color: $color-shadow;
      svg {
        color: $color-product-panel;
      }
    }
  }
}

.progressBarWithPercent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.progressBar {
  width: 85%;
  height: 15px;
  background-color: $color-gray;
  border-radius: 20px;

  div {
    height: 100%;
    background-color: $color-shadow;
    border-radius: 15px;
  }
}

.addPhotoInfo {
  color: #ff0000;
  font-weight: bold;
  padding: 20px;
  text-align: center;
}
