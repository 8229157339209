.list-container
{
  height: calc(100vh - 45px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-list
{
  height: 65vh;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  table {
    border-collapse: collapse;

    thead {
      position: sticky;
      top: 0;
      background-color: #e3e3e3;
    }

    thead th {
      font-weight: bold;
      border-radius: 0;
    }

    th, td {
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      vertical-align: top;
      line-height: 2.2rem;

      .header {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .description {
        overflow: hidden;
        height: 140px;
        max-width: 500px;
        text-overflow: ellipsis;
      }

      .icon {
        svg {
          font-size: 37px;
        }

        .MuiSvgIcon-root {
          color: black;
          transition: all 0.3s ease-in-out;
          &:hover {
            transform: scale(1.5);
          }
        }
      }

      img {
        width: 200px;
        height: 200px;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color-product-panel;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-product-panel;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $color-shadow;
  }
}



