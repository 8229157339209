.form {
  width: 60%;
  padding: 70px 180px;
  display: flex;
  justify-content: center;

  .questionList {
    display: flex;
    // justify-content: center;
    align-self: flex-start;
    // .numbering {
    //   font-family: $font-title;
    //   text-transform: uppercase;
    //   font-weight: 600;
    //   color: $color-product-panel;
    //   font-size: 28px;
    //   margin-right: 30px;
    // }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title,
    .title2 {
      text-align: center;
      justify-self: center;
      font-family: $font-title;
      font-size: 18px;
    }
    .title {
      color: $color-product-panel;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 12px;
    }
    .title2::first-letter {
      text-transform: uppercase;
    }
  }

  .buttonBox {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }

  .error {
    color: red;
    text-align: center;
    font-size: 1.5rem;
  }
}

.buttonBox {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.form .MuiButton-contained,
.login .MuiButton-contained {
  background-color: $color-product-panel;
  padding: 15px 40px;
  border-radius: 50px;
  &:hover {
    background-color: $color-shadow;
  }
}
.sBtn .MuiButton-contained {
  svg {
    font-size: 40px;
  }
  padding: 11px 11px;
  &:first-child {
    margin-right: 5px;
  }
  .MuiButton-endIcon,
  .MuiButton-startIcon {
    margin: 0;
  }
}

.saveBtn {
  svg {
    margin-left: 5px;
  }
}

.switch,
.radio,
.range,
.input,
.radioPart {
  margin-bottom: 30px;
  label {
    color: black;
  }
  p {
    margin-bottom: 20px;
    line-height: 1.4em;
  }
}

.names .input {
  margin-bottom: 15px;
}

.radioPart {
  margin-bottom: 30px;
}

.emptyCell {
  border: red 2px solid;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}

//SWITCH

.form .noAnswer {
  span.lever {
    background-color: $color-gray;
  }
  label input[type="checkbox"] + .lever:after {
    background-color: $color-gray;
  }
}

.switch {
  min-width: 30vw;

  span.lever {
    background-color: #cbe6bb;
  }

  label .lever:before {
    background-color: $color-product-panel;
  }

  label input[type="checkbox"] + .lever:after {
    background-color: $color-accent;
  }

  label input[type="checkbox"]:checked + .lever:after {
    background-color: $color-product-panel;
  }
}

//RADIO

.radio {
  display: flex;
  flex-direction: column;
  width: 100%;
  .radioPart {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  .img {
    background-repeat: no-repeat;
    background-position: top;
    height: 100px;
    width: 160px;
    margin-top: 10px;
    background-size: contain;
  }

  .simplyRadio {
    max-width: 160px;
    margin: 0 40px 10px 0;
    display: inline-block;
    text-align: center;
  }

  [type="radio"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  [type="radio"] + img {
    cursor: pointer;
  }

  [type="radio"]:checked + div {
    outline: 2px solid $color-product-panel;
  }
}

//RANGE

.form .noAnswer {
  div .MuiSlider-root {
    color: $color-gray;
  }
  .MuiSlider-thumb::after {
    color: $color-product-panel;
  }
}

.range {
  div {
    width: 100%;
    .MuiSlider-root {
      width: 35vw;
      color: $color-product-panel;
      .MuiSlider-rail {
        height: 7px;
        padding-left: 2px;
      }
    }
  }
}

//INPUT

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-field {
  margin-top: 0;
}

.input-field > label {
  position: static;
}

.input {
  width: 35vw;

  .input-field input[type="text"]:focus + label,
  .input-field input[type="number"]:focus + label {
    color: $color-product-panel;
  }
  .input-field input[type="text"]:focus,
  .input-field input[type="number"]:focus,
  .input-field input[type="text"].valid,
  .input-field input[type="number"].valid {
    border-bottom: 1px solid $color-product-panel;
    box-shadow: 0 1px 0 0 $color-product-panel;
  }
}

//NAME

.name {
  input[type="text"]:not(.browser-default),
  input[type="text"]:not(.browser-default):focus:not([readonly]) {
    box-shadow: none;
    padding: 0 0 0 10px;
  }
  .btnName {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
}

.names {
  .MuiAlert-filledWarning {
    position: absolute;
    top: 15vh;
    left: 20vw;
  }
  .name .title {
    margin-bottom: 35px;
  }
}

.form .MuiPaper-root {
  height: 50px;
  min-width: 80%;
  margin-top: 50px;
  width: 100%;
  font-size: 1rem;
}

.readonly {
  background-color: #d3d3d3 !important;
}
