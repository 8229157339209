@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;400;600;800&display=swap");

$font-primary: "Roboto Slab", serif;
$font-title: "Exo", sans-serif;

body {
  // background-color: $color-main-background;
  font-family: $font-title;
  min-height: 100vh;
  font-weight: 400;
  font-size: 18px;
  // background-image: linear-gradient(
  //   to right top,
  //   #d18d0f,
  //   #c95435,
  //   #a3294d,
  //   #661a56,
  //   #1d1648
  // );

  // background-color: #006073;
  // background-color: #548687;
  // background-color: #95d07d;

  background-image: linear-gradient(
    to top,
    #006073,
    #007c82,
    #009783,
    #4cb179,
    #8cc769
  );
}

$color-main-background: #6b7698;
$color-gray: #e3e3e3;


$color-product-panel: #006073;
$color-shadow: #8cc769;
$color-accent: #8cc769;

#mynetwork {
  width: 100vw;
  height: 100vh;
  // border: 1px solid lightgray;
  // background-color: #333333;
}

.pull-left
{
  float: left;
}

.pull-right
{
  float: right;
}

.text-bold
{
  font-weight: bold !important;
}

.btn-yes { background-color: $color-product-panel !important; color: $color-gray !important; }
.btn-no { background-color: $color-gray !important; color: $color-product-panel !important; }
