.footer{
    background-color: white;
    text-align: center;
    height: 112px;
    padding-bottom: 0px;
    margin-bottom: 0;
}


.contrast {
    background-color: black;
    background-image: none;
    min-height: 100vh;
    height: 100%;
    .MuiButton-contained, 
    .divWithTable .divWithHeading .MuiButton-contained,
    .product .card-title, .form form .title{
        color: black;}
    .login .MuiButton-contained {
            color: white;
        }
    .divSelectPhoto .buttonBox .MuiButton-contained,
    .productsListTab ::-webkit-scrollbar-thumb,
    .form .MuiButton-contained, .login .MuiButton-contained,
    .backBtn button{
        background-color: #A23006;
    }
    .productsListTab ::-webkit-scrollbar-track{
        box-shadow: inset 0 0 5px #A23006;
    }
    .login .input-field input[type="email"]:focus, .login .input-field input[type="password"]:focus{
       border-bottom:  1px solid #A23006;
       box-shadow: 0 1px 0 0 #A23006;
    }
    div .menu ul{
        .closeMenu div {
        background-color: black;
         }
        li a{
        color: black;
        }
        .active{
        color: #A23006;
        }
    }
}


.contrastModal{
    svg{
        color: black;
    }
    .addPhoto .MuiButton-contained:hover,
    .divSelectPhoto .buttonBox .MuiButton-contained,
    .addPhoto .MuiButton-contained,
    .progressBar div{
        background-color: black;
    }
    .addPhoto .MuiButton-contained:hover svg{
        color: white
    }
}

.textSize {
    font-size: 35px;
    .divWithHeading .card-title{
        font-size: 50px;
    }
    .topBar,
    .searchBox input[type="text"]:not(.browser-default){
        height: 65px;
        padding-left: 65px;

    }
    .topBar img {
        width: 152px;
        height: 65px;
    }
    .searchBox input[type="text"]:not(.browser-default){
        width: calc(100% - 65px);
        font-size: 35px;
    }
    .searchBox{
        height: 110px;
    }
    .MuiSvgIcon-root{
        font-size: 45px;
    }
    .searchBox svg{
        top: 60px;
    }
    .divWithTable .divWithHeading{
        margin-top: 0;
    }
    div .menu ul .closeMenu .MuiSvgIcon-root{
        font-size: 35px;
    }
    div .menu ul li{
        font-size: 40px;
    }
    .MuiButton-label{
        font-size: 30px;
    }
    .addZebra {
    input[type="text"]:not(.browser-default){
        height: 2.5rem;
        font-size: 30px;
    }
}
}


.textSizeModal{
    .MuiButton-label,
    .addPhoto p{
        font-size: 30px;
    }
    .progressBar{
        height: 30px;
    }
}