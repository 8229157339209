.login {
  height: calc(100vh - 112px);
  display: flex;
  align-items: center;
  .MuiButton-contained {
    font-size: 12px;
    padding: 12px 50px;
    letter-spacing: 1px;
    margin-top: 70px;
  }

  p {
    color: red;
    font-size: 14px;
    text-align: center;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
  }

  input {
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }

  .input-field input[type="email"]:focus,
  .input-field input[type="password"]:focus {
    border-bottom: 1px solid $color-product-panel;
    box-shadow: 0 1px 0 0 $color-product-panel;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    min-height: 480px;
    h1 {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }

  .form-container {
    position: absolute;
    height: 100%;
    width: 50%;
  }

  .overlay-container {
    position: absolute;
    left: 50%;
    width: 50%;
    height: 100%;
  }

  .overlay {
    background: $color-gray;
    left: -100%;
    height: 100%;
    width: 200%;
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 80px;
    height: 100%;
    right: 0;
  }
}
