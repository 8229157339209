.ReactModalPortal {
  // overflow: auto;
  border-radius: 4px;
  outline: none;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  z-index: 1;
}

.ReactModal__Body--close {
  display: none;
}

.insideModal {
  position: relative;
  border: 1px solid #ccc;
  background: #fff;
  width: 760px;
  max-height: calc(100vh - 80px);
  margin: 40px;
  margin-top: 56px;
  padding: 20px;
  overflow-y: auto;

  svg {
    font-size: 32px;
    padding: 0 0 5px 10px;
    float: right;
    color: $color-accent;
    cursor: pointer;
  }
}

.insideModal .btnAddPatient,
.mdt form .btnAddPatient {
  align-self: center;
  height: 48px;
  svg {
    color: white;
    font-size: 20px;
    padding: 0;
  }
}

.mdt form .btnAddPatient svg {
  margin-bottom: 2px;
}

@media screen and (max-width: 650px) {
  .insideModal {
    margin-top: 56px;
    width: 100vw;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
