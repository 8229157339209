.divAddZebra {
  width: 100%;
  height: auto;
  .addZebra {
    display: flex;
    flex-direction: column;
  }
  .divWithHeading > div {
    margin-top: 20px;
  }
}

.divWithTable .divWithHeading {
  margin-top: 25px;
}

.divSelectPhoto {
  height: 86vh;
  width: 100%;
  tr {
    cursor: pointer;
  }
}

.description {
  overflow: hidden;
  height: 140px;
  text-overflow: ellipsis;
}

.divSelectPhoto .selectPhotoList .markDiv {
  border: 3px solid red;
}

.selectPhotoList {
  height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;

  .allPhotos {
    margin: 5px;
    margin-left: 25px;
    margin-bottom: 20px;
    border: 3px solid white;
    cursor: pointer;
    .productListImg {
      width: 100px;
      height: 100px;
    }
  }

  .divWithMiniLoaderSelectPhoto {
    width: 100px;
    height: 100px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-content: center;
    svg {
      padding: 0;
    }
  }
}

.divSelectPhoto .selectPhotoSpin {
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 2px solid black;
  margin: 5px;
  margin-left: 25px;
  margin-bottom: 20px;
  text-align: center;
  padding: 5px;
  font-size: 70%;
  line-height: 1.3;
  svg {
    margin-top: 28px;
    padding: 0;
    font-size: 26px;
  }
}

.MuiSelect-root,
.MuiSelect-select,
.MuiSelect-selectMenu,
.MuiInputBase-input,
.MuiInput-input {
  background-color: white;
}

.MuiInputBase-root {
  border-bottom: none;
}
.MuiFormControl-root {
  background-color: white;
  width: 320px;
  height: 40px;
  border-radius: 50px;
  border-bottom: none;
}

.MuiSelect-select.MuiSelect-select {
  padding: 12px 0 0 20px;
  height: 28px;
  &:focus,
  &:hover {
    opacity: 1;
    border-bottom: none;
    border-radius: 50px;
  }
}

ul:not(.browser-default) {
  cursor: pointer;
}

.addZebraPart1 {
  justify-content: space-between;
  width: 320px;
  button {
    margin-bottom: 20px;
    width: 320px;
  }
}

.addZebraPart2 {
  button {
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .showSelectedPhoto {
    width: 100%;
  }
}
.addZebra {
  // display: flex;
  // justify-content: center;
  margin-top: 25px;
  width: 70vw;
  h3 {
    margin: 20px 0 20px;
    color: white;
  }
  h4 {
    text-align: center;
    color: white;
    font-weight: bold;
    height: 1.5rem;
  }
}

.showSelectedPhoto {
  background-color: white;
  width: 170px;
  height: 170px;
  align-self: center;
  img {
    width: 165px;
    height: 165px;
    border: 5px white solid;
  }
}

.tox :not(svg):not(rect) {
  z-index: 0;
}
.MuiFormControl-root .MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiFormControl-root .MuiInput-underline:before,
.MuiFormControl-root .MuiInput-underline:after {
  border-bottom: 0px;
  transition: none;
}

.addZebra .addPresentationPart1 {
  display: flex;
  justify-content: space-between;
}

.addZebraLoader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divAddZebra .divWithHeading .deleteBtn {
  color: red;
  margin-right: 40px;
}

.addZebra input[type="text"]:not(.browser-default) {
  background-color: white;
  padding: 8px 30px;
  border-radius: 50px;
  width: 90%;
  height: 1.5rem;
}
.divWithHeading .divAddPresentation .MuiButton-contained {
  width: 320px;
  margin-bottom: 20px;
}

.divAddZebra .divWithHeading .divAddPresentation .buttonBox button {
  width: 145px;
}

.divSelectPhoto .buttonBox {
  margin-top: 20px;
  .MuiButton-contained {
    background-color: $color-product-panel;
    color: white;
    padding: 8px 25px;
    border-radius: 50px;
    cursor: pointer;
    svg {
      padding: 0px;
      color: white;
    }
  }
}

@keyframes deletePhoto {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.list__card-left {
  position: relative;

  .deleteSinglePhoto {
    width: 35px;
    height: 35px;
    position: absolute;
    top: -15px;
    right: -15px;
    border-radius: 50%;
    border: none;
    background-color: #f1243d;
    cursor: pointer;
    box-shadow: 0px 0px 34px -10px rgba(56, 61, 80, 1);
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;

    &:hover {
      animation: deletePhoto 0.8s ease-in-out infinite;
    }
    &:active {
      background-color: #9c1d2c;
    }
  }

  .showSinglePhoto {
    width: 35px;
    height: 35px;
    position: absolute;
    top: -15px;
    right: 145px;
    border-radius: 50%;
    border: none;
    background-color: yellow;
    cursor: pointer;
    box-shadow: 0px 0px 34px -10px rgba(56, 61, 80, 1);
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    line-height: 15px;

    &:hover {
      animation: deletePhoto 0.8s ease-in-out infinite;
    }
    &:active {
      background-color: yellow;
    }
  }
}

.alert {
  .MuiAlert-filledSuccess {
    position: absolute;
    top: -40px;
    left: 0px;
    font-size: 20px;
    padding-left: 40vw;
    width: 100vw;
    background-color: #007c82;
    height: 37px;
  }
  .MuiAlert-message {
    padding-top: 0;
  }
  animation: alert 5s infinite ease-in-out;
}

@keyframes alert {
  0% {
    transform: translate(0px, -40px);
  }
  10% {
    transform: translate(0px, 40px);
  }
  90% {
    transform: translate(0px, 40px);
  }
  100% {
    transform: translate(0px, -40px);
  }
}

.onlyZebra .buttonBox {
  margin-top: 90px;
}

.zebrasHeader {
  font-weight: bold;
}

.selectPhotoList {
  img {
    color: #900;
    text-decoration: none;
  }

  img:hover {
    color: red;
    position: relative;
  }

  img[title]:hover:after {
    content: attr(data);
    padding: 4px 8px;
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 100%;
    white-space: nowrap;
    z-index: 2;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
  }
}
