.topBar {
  background-color: white;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  padding: 0 20px;

  div {
    display: flex;
    align-items: center;
    margin-right: 20px;
    p {
      margin: 0 5px;
      margin-right: 35px;
    }
    svg:nth-child(4),
    div svg {
      cursor: pointer;
      margin-left: 14px;
      transition: all 0.3s ease-in-out;
      &:hover {
        font-weight: bold;
        transform: scale(1.5);
      }
    }
  }
}
