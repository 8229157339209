div .menu {
  height: calc(100vh - 45px);
  background-color: rgba(255, 255, 255, 0.97);
  margin: 0;
  align-items: flex-start;
  border: 1px solid $color-product-panel;
  border-right: none;
  z-index: 1800;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  ul {
    width: 100%;
    // text-align: end;
    li {
      cursor: pointer;
      font-size: 20px;
      padding: 20px;
      padding-left: 80px;
      color: $color-product-panel;
      font-weight: bold;
      &:hover {
        color: $color-accent;
      }
    }
    .active {
      color: $color-accent;
    }
    .closeMenu {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-left: 40px;
      div {
        margin: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-product-panel;
        &:hover {
          background-color: $color-accent;
        }
      }
      svg {
        font-size: 35px;
        margin: 0;
        margin-left: 7px;
        color: white;
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}
