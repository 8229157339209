// .list {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(42rem, 1fr));
//   // background-color: #f7f7f7;
//   height: fit-content;
//   margin-bottom: 4rem;
//   min-height: 250px;
// }

.list {
  display: flex;
  // grid-template-columns: repeat(auto-fit, minmax(42rem, 1fr));
  // justify-content: space-between;
  flex-wrap: wrap;
  // background-color: #f7f7f7;
  height: fit-content;
  margin-bottom: 4rem;
  min-height: 250px;
}

.list__card {
  // display: grid;
  // grid-template-columns: 1fr 1fr;
  // height: 20rem;
  // height: 50px;
  margin: 3rem 3.5rem 3rem 0;
  box-shadow: 0 0.7rem 1.2rem rgba(0, 0, 0, 0.2);
  cursor: pointer;
  // transition: all 0.3s;
  img {
    height: 165px;
    width: 165px;
  }
}

.list__card:hover {
  transform: translateY(-2px);
  box-shadow: 0 1.4rem 2.4rem rgba(0, 0, 0, 0.2);
}

.list__card-left {
  display: flex;
  height: 165px;
  width: 165px;
  justify-content: center;
  align-items: center;
  background-color: white;
}

// .list__card-right {
//   display: flex;
//   flex-direction: column;
//   margin: 2rem;
//   justify-content: space-between;
// }

// .list__card-right--name {
//   display: flex;
//   align-self: center;
//   text-align: center;
//   font-size: 2rem;
//   font-weight: 800;
// }

// .list__card-right--description {
//   display: flex;
//   font-size: 1.6rem;
//   align-self: center;
//   text-align: center;
// }

// .list__card-right--price {
//   display: flex;
//   font-size: 1.6rem;
//   align-self: flex-end;
// }

// .list__title {
//   display: flex;
//   font-size: 3rem;
//   font-weight: 800;
//   letter-spacing: 0.7rem;
//   margin-left: 0.7rem;
//   margin-top: 3rem;
//   justify-content: center;
// }
