.divWithTable {
  height: calc(100vh - 80px);
  width: 90%;
  .divWithHeading {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: space-around;
    .MuiButton-contained {
      background-color: white;
      color: $color-product-panel;
      padding: 8px 25px;
      border-radius: 50px;
      cursor: pointer;
    }
  }
}

.divWithHeading {
  .card-title {
    font-family: $font-title;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    font-size: 34px;
  }
}

.divWithMiniLoader {
  width: 315px;
  display: flex;
  justify-content: center;
  div {
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:not(.patientListImg) {
      border: 1px solid black;
    }
  }
}

.productsList {
  height: calc(100vh - 45px);
  // width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productsListTab {
  // width: fit-content;
  border-collapse: collapse;
  // height: 79%;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  svg {
    cursor: pointer;
  }

  tbody,
  tr {
    padding: 0 30px;
  }
  tr {
    padding-top: 10px;
  }

  th,
  td {
    padding: 10px;
    border-bottom: 1px solid $color-gray;
    vertical-align: top;
    line-height: 2.2rem;
  }

  thead {
    font-weight: 600;
    text-align: left;
    tr {
      display: block;
      padding-right: 42px;
    }
  }

  thead {
    display: block;
  }

  tbody {
    display: block;
    overflow-x: auto;
    // height: 100%;
    height: 65vh;
    .MuiSvgIcon-root {
      color: black;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.5);
      }
    }
  }

  .chooseProductIcon {
    svg {
      font-size: 37px;
      // &:hover {
      //   color: $color-product-panel;
      // }
    }
  }

  .patientListImg {
    background-repeat: no-repeat;
    background-position: top;
    height: 150px;
    width: 150px;
    background-size: contain;
    justify-self: center;
  }

  th:first-child,
  td:first-child {
    width: 10%;
  }

  th:nth-child(2),
  td:nth-child(2) {
    width: 20%;
  }

  th:nth-child(3),
  td:nth-child(3) {
    width: 25%;
  }

  th:nth-child(4),
  td:nth-child(4) {
    width: 15%;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 10%;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 10%;
  }

  th:last-child,
  td:last-child {
    width: 10%;
  }

  th:first-child {
    width: 14%;
  }

  th:nth-child(2) {
    width: 16%;
  }

  th:nth-child(3) {
    width: 30%;
  }

  th:nth-child(4) {
    width: 14%;
  }
  th:nth-child(5) {
    width: 11%;
  }
  th:nth-child(6) {
    width: 11%;
  }

  th:last-child {
    width: 11%;
    padding-right: 80px;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $color-product-panel;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-product-panel;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $color-shadow;
  }
}
tbody {
  width: 100%;
}
.searchBox {
  margin-bottom: 20px;
  // width: 100%;

  input[type="text"]:not(.browser-default) {
    height: 45px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin: 0;
    border: 2px solid white;
    padding-left: 45px;
    width: calc(100% - 45px);
    &:focus {
      outline: none;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
      border: 2px solid $color-product-panel;
    }
  }

  svg {
    position: relative;
    font-size: 30px;
    top: 40px;
    left: 10px;
  }
}

@media screen and (max-width: 650px) {
  .productsList {
    height: 100vh;
  }
  .productsListTab {
    margin: 0;

    tbody,
    tr {
      padding: 0 0;
    }

    tbody {
      height: 100vh;
      width: 100vw;
      td {
        width: 30vw;
      }
    }
  }

  .productsList {
    table,
    tr,
    td {
      display: block;

      thead {
        display: none;
      }

      tr {
        border-top: 1px solid #bfc8cf;
      }

      td {
        padding-left: 45%;
        position: relative;
        border: none;

        &:before {
          position: absolute;
          font-weight: 700;
          left: 20px;
        }

        &:first-child:before {
          content: "PID";
        }

        &:nth-child(2):before {
          content: "Zdjęcie";
        }

        &:nth-child(3):before {
          content: "Nazwa";
        }

        &:nth-child(4):before {
          content: "Kod";
        }

        // &:nth-child(5):before {
        //   content: " ";
        // }

        &:last-child:before {
          content: " ";
        }
      }
    }
  }
}
