.card {
  min-height: 90vh;
  margin: 2.5vh;
  border-radius: 20px;
}

.row {
  display: flex;
  margin: 0;
}

.div {
  width: 500px;
  height: 500px;
  background-color: red;
}

.backBtn {
  justify-self: flex-end;
  margin-bottom: 40px;
  button {
    background-color: $color-product-panel;
    padding: 15px 40px;
    border-radius: 50px;
    &:hover {
      background-color: $color-shadow;
    }
  }
}

.product {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  position: sticky;
  top: 50px;
  height: max-content;

  .card-image {
    width: 70%;
    margin-top: 24px;
    img {
      border-radius: 1%;
    }
  }

  .card-title {
    font-family: $font-title;
    text-transform: uppercase;
    font-weight: 500;
    color: $color-product-panel;
  }

  .card-content {
    padding: 24px 4vw;
    line-height: 1.4em;
    text-align: justify;
    .card-title {
      text-align: center;
    }
  }

  .card-action {
    font-family: $font-title;
    font-style: italic;
    font-weight: 500;
  }
}
.product .MuiAlert-filledError {
  height: 50px;
  min-width: 80%;
  margin-top: 50px;
}
