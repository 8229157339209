@media screen and (max-width: 1400px) {
  .product {
    padding: 10px;
  }
}
@media screen and (max-width: 1150px) {
  .row {
    display: block;
    .product {
      width: auto;
      position: static;
    }
    .form {
      width: auto;
      .radio {
        div {
          margin: 0 20px 10px 0;
        }
      }
      .range {
        div {
          display: flex;
          flex-direction: column;
          width: 70vw;
          .MuiSlider-root {
            width: 65vw;
            align-self: center;
          }
        }
      }
      .input {
        width: 55vw;
      }
    }
  }
  div .topBar {
    padding: 0px;
  }
}

@media screen and (max-width: 800px) {
  .row {
    .form {
      text-align: justify;
      padding: 70px 20px;
      .questionList {
        .numbering {
          margin-right: 30px;
        }
      }
    }
  }
  .login {
    .overlay-container {
      display: none;
    }
    .container {
      margin-top: 10vh;
      height: 100%;
      .form-container {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .row {
    .form {
      // padding: 30px 20px;
      .questionList {
        .numbering {
          margin-right: 20px;
        }
      }
    }
  }
  .form .MuiButton-contained {
    padding: 14px 14px;
    border-radius: 50px;
    font-size: 13px;
  }
}

@media screen and (max-width: 450px) {
  .row {
    .form {
      .range {
        div {
          .MuiSlider-root {
            align-self: flex-start;
          }
        }
      }
    }
  }
  div .topBar {
    div {
      margin-right: 10px;
      p {
        margin: 0 4px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .topBar div {
    svg:first-child {
      display: none;
    }
    p {
      display: none;
    }
  }
}
